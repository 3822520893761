/* CSS for smaller screens (mobile-first) */
.experience-heading {
  font-size: 24px;
  text-align: left;
}

.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 1rem 1rem;
}

/* Media Query for medium-sized screens */
@media (min-width: 769px) {
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }

  .experience-cards-div {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

/* Media Query for larger screens */
@media (min-width: 1381px) {
  .experience-heading {
    font-size: 40px;
    text-align: left;
  }

  .experience-cards-div {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Adjust the minmax value */
  }
}

/* Media Query for screens between 887px and 1380px */
@media (min-width: 887px) and (max-width: 1380px) {
  .experience-cards-div {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr)); /* Adjust the minmax value */
  }
}

.experience-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px;
  margin-top: 2rem;
}

.experience-container > * {
  flex: 1;
}
